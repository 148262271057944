import axiosClient, { AxiosInstance } from 'axios';
import storage from '../utils/storage';
import { useAppStore } from '../store';

const TIMEOUT = 100000; // 100 seconds
const SERVER_URL = process.env.REACT_APP_API_HOST || 'https://api.layerx.baboons.tech';
const appStore = useAppStore.getState();

const axios: AxiosInstance = axiosClient.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': '9KVvI9QM_98vtE__EYrhCgxFad-6do8fRB9050923uc',
  },
  timeout: TIMEOUT,
});

let refreshAttemptCount = 0; // Track refresh attempts

const refreshToken = async () => {
  try {
    const refreshAccessToken = storage.get('refreshAccessToken') || '';

    if (refreshAccessToken.length > 0) {
      console.log('Attempting to refresh token');
      const endPoint = 'api/user/token/refresh/';
      const res = await axios.post(endPoint, { refresh: refreshAccessToken });

      if (res.data && res.data.access && res.data.refresh) {
        return res.data; // Ensure this is a valid data structure
      } else {
        console.log('Received corrupt token data');
      }
    }
  } catch (e) {
    console.log('Error refreshing token', e);
  }

  return null; // Return null or an appropriate response when refresh fails
};

axios.interceptors.request.use(
  (config) => {
    const token = storage.get('accessToken');
    if (token && config?.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    // Check if the request was already retried
    if (originalRequest._retry) {
      return Promise.reject(error);
    }

    // Check for 401 Unauthorized and attempt to refresh the token
    if (error?.response?.status === 403 || error?.response?.status === 401) {
      originalRequest._retry = true;

      console.log('refreshAttemptCount', refreshAttemptCount);

      // Check if we've exceeded the max refresh attempts
      if (refreshAttemptCount >= 2) {
        console.log('Max refresh attempts reached. Logging out.');
        appStore.setAccessToken('');
        storage.clear();
        return Promise.reject(error); // Reject the promise after reaching max retries
      }

      refreshAttemptCount = refreshAttemptCount + 1; // Increment refresh attempt count
      const res = await refreshToken();

      if (res && res.access) {
        // Successful refresh, set new tokens
        appStore.setAccessToken(res.access);
        storage.set('accessToken', res.access);
        storage.set('refreshAccessToken', res.refresh);

        // Set the Authorization header for subsequent requests
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.access}`;

        // Retry the original request with the new token
        return axios(originalRequest);
      } else {
        // Refresh failed or corrupt token received, log the user out
        console.log('Refresh failed. Logging out.');
        appStore.setAccessToken('');
        storage.clear();
      }
    }

    return Promise.reject(error);
  },
);

export default axios;

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  AllNftsResponseData,
  CompetitionObject,
  CompetitionResponse,
  IUser,
  MyCompetitionResponse,
} from './interfaces/components';
import Web3 from 'web3';

export interface AppStoreState {
  user: IUser | null;
  signatureSet: string;
  web3: Web3 | null;
  walletAddress: string;
  accessToken: string | null;
  loading: boolean;
  reRender: boolean;
  mintedImage: string | undefined;
  numberOfComments: number | null;
  walletLinked: boolean;
  setWalletLinked: (loading: boolean) => void;
  setNumberOfComments: (noOfCommented: number | null) => void;
  setMintedImage: (signatureSet: string | undefined) => void;
  setRerender: (signatureSet: boolean) => void;
  setSignature: (signatureSet: string) => void;
  setLoading: (loading: boolean) => void;
  setWeb3: (data: Web3) => void;
  setWalletAddress: (address: string) => void;
  setAccessToken: (accessToken: string | null) => void;
  setUser: (user: IUser) => void;
  competitionList: CompetitionResponse | null;
  setCompetitionList: (list: CompetitionResponse) => void;
  myCompetitionList: MyCompetitionResponse | null;
  setMyCompetitionList: (list: MyCompetitionResponse) => void;
  selectedCompetitionId: number | null;
  setSelectedCompetitionId: (competition: number | null) => void;
  addNftToCompetitionStatus: string;
  setAddNftToCompetitionStatus: (status: string) => void;
  mintNowView: boolean;
  setMintNowView: (mintNowView: boolean) => void;
  selectedNft: any;
  setSelectedNft: (nft: any) => void;
  competitionDetails: CompetitionObject | null;
  setCompetitionDetails: (competitionDetails: CompetitionObject) => void;
}

const LOCAL_STORAGE_KEY = 'accessToken';
const SIGNATURE_BOOLEAN = 'signatureBoolean';

const getInitialLoginApiResponse = (): string | null => {
  const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
  return storedData ? JSON.parse(storedData) : null;
};

const getInitialSignatureState = () => {
  const loggedIn = localStorage.getItem(SIGNATURE_BOOLEAN) || 'no';
  return loggedIn;
};

export const useAppStore = create<AppStoreState>()(
  devtools((set) => ({
    accessToken: getInitialLoginApiResponse(),
    signatureSet: getInitialSignatureState(),
    reRender: false,
    web3: null,
    walletAddress: '',
    user: null,
    refreshToken: null,
    loading: false,
    payoutList: null,
    clientsList: null,
    investmentsList: null,
    competitionList: null,
    myCompetitionList: null,
    selectedCompetitionId: null,
    addNftToCompetitionStatus: '',
    mintNowView: false,
    selectedNft: null,
    competitionDetails: null,
    mintedImage: undefined,
    numberOfComments: null,
    walletLinked: false,
    setWalletLinked: (walletLinked: boolean) => set(() => ({ walletLinked: !walletLinked })),
    setNumberOfComments: (noOfComments: number | null) => set(() => ({ numberOfComments: noOfComments })),
    setMintedImage: (mintedImage: string | undefined) => set(() => ({ mintedImage: mintedImage })),
    setCompetitionDetails: (competitionDetails: CompetitionObject) =>
      set(() => ({ competitionDetails: competitionDetails })),
    setSelectedNft: (selectedNft: AllNftsResponseData | string | null) => set(() => ({ selectedNft: selectedNft })),
    setMintNowView: (mintNowView: boolean) => set(() => ({ mintNowView: mintNowView })),
    setAddNftToCompetitionStatus: (addNftToCompetitionStatus: string) =>
      set(() => ({ addNftToCompetitionStatus: addNftToCompetitionStatus })),
    setSelectedCompetitionId: (selectedCompetitionId: number | null) =>
      set(() => ({ selectedCompetitionId: selectedCompetitionId })),
    setCompetitionList: (competitionList: CompetitionResponse) => set(() => ({ competitionList: competitionList })),
    setMyCompetitionList: (myCompetitionList: MyCompetitionResponse) =>
      set(() => ({ myCompetitionList: myCompetitionList })),
    setSignature: (signatureSet: string) => {
      // Save to local storage
      if (signatureSet) {
        localStorage.setItem(SIGNATURE_BOOLEAN, signatureSet);
      } else {
        localStorage.removeItem(SIGNATURE_BOOLEAN);
      }
      set(() => ({ signatureSet: signatureSet }));
    },
    setUser: (user) => set(() => ({ user: user })),
    setAccessToken: (accessToken) => set(() => ({ accessToken: accessToken })),
    setLoading: (loading) => set(() => ({ loading: loading })),
    setLoginApiResponse: (accessToken: string) => {
      // Save to local storage
      if (accessToken) {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(accessToken));
      } else {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      }
      set(() => ({ accessToken: accessToken }));
    },
    setWeb3: (data: Web3) => set(() => ({ web3: data })),
    setWalletAddress: (address: string) => set(() => ({ walletAddress: address })),
    setRerender: (reRender: boolean) => set(() => ({ reRender: reRender })),
  })),
);

import { Box, Text, Image, useToast, Divider, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import EthereumLogo from '../../Icons/Ethereum';
import HeartIcon from '../../Icons/HeartcIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { addNftToFavorite, getNftDetails } from '../../api/nfts/apis';
import { useAppStore, AppStoreState } from '../../store';
import VerifiedLogo from '../../Icons/Verified';
import { NftType, OwnerObject } from '../../interfaces/components';
import CommentSection from './CommentSection';
import HeartFilledIcon from '../../Icons/HeartFilledIcon';

export default function NftDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const reRender = useAppStore((state: AppStoreState) => state.reRender);
  const loading = useAppStore((state: AppStoreState) => state.loading);
  const [nftDetail, setNftDetail] = useState<NftType>();
  const setLoading = useAppStore((state: AppStoreState) => state.setLoading);

  const fetchData = async (tokenId: string) => {
    try {
      setLoading(true);
      const res = await getNftDetails(tokenId);
      res && setNftDetail(res?.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Something went wrong while fetching NFT details',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const tokenId = location && location?.pathname?.split('/').pop();
    if (tokenId) {
      fetchData(tokenId);
    }
  }, [reRender, location?.pathname]);

  const onAddToFavClick = async () => {
    try {
      const res = await addNftToFavorite(nftDetail?.identifier || '');
      if (res?.message === 'NFT liked') {
        const tokenId = location && location?.pathname?.split('/').pop();
        tokenId && fetchData(tokenId);
        // setNftLiked(true);
      }
    } catch (error: unknown) {
      toast({
        title: 'Something went wrong while adding NFT to favorites',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const removeFromFavorites = async () => {
    try {
      const res = await addNftToFavorite(nftDetail?.identifier || '');
      if (res?.message === 'NFT unliked') {
        const tokenId = location && location?.pathname?.split('/').pop();
        tokenId && fetchData(tokenId);
        // setNftLiked(false);
      }
    } catch (error: unknown) {
      toast({
        title: 'Something went wrong while removing NFT from favorites',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleShare = async () => {
    try {
      const currentURL = window.location.href;
      await navigator.clipboard.writeText(currentURL);
      toast({
        title: 'Link copied!',
        description: 'You can now share the copied link',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    } catch (err) {
      console.error('Failed to copy the link', err);
      toast({
        title: 'Failed to copy link',
        description: 'Something went wrong, please try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  console.log('nftDetail', nftDetail, nftDetail?.display_image_url);

  const onOwnerClick = (owner: OwnerObject) => {
    navigate(`/users-profile/${owner?.twitter_username}`, { state: { owner } });
  };

  return (
    <>
      {loading ? (
        <Box>{/* <LoadingSpinner /> */}</Box>
      ) : (
        <Box
          // margin="0px 16px"
          padding="12px 0px 64px 0px"
          display={['flex', 'flex', 'block']}
          flexDirection={['column', 'column', 'unset']}
        >
          <Box>
            <Text color="rgba(255, 255, 255, 0.50)" fontSize="14px" fontWeight="600">
              NFTs Overview/{' '}
              <span
                style={{
                  color: '#FFF',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                &nbsp;NFT Preview
              </span>
            </Text>
          </Box>

          <Box marginTop="24px" display="flex" flexDirection={['column', 'column', 'row', 'row']} gap="24px">
            <Box
              borderRadius="26px"
              objectFit="cover"
              // maxHeight="334px"
              overflow="auto"
              display={['block', 'block', 'none', 'none', 'none']}
              width="-webkit-fill-available"
            >
              <Image
                // src={'https://ipfs.io/ipfs/bafkreidxofoqnlhzdgsbvorkpadstxk4tabxnqvcsbmnlua4m7ok74hqqe?file.png'}
                src={nftDetail?.display_image_url}
                objectFit="cover"
                width="inherit"
                borderRadius="26px"
              />
            </Box>
            <Box width={['100%', '100%', '50%']} display="flex" flexDirection="column" gap="24px">
              <Box
                borderRadius="26px"
                objectFit="cover"
                maxHeight="334px"
                overflow="auto"
                display={['none', 'none', 'block', 'block', 'block']}
                width="-webkit-fill-available"
                css={{
                  '&::-webkit-scrollbar': {
                    width: '8px !important',
                    backgroundColor: 'transparent !important',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'rgba(255,255,255,0.2) !important',
                    borderRadius: '16px !important',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#b7b7b7 !important',
                    borderRadius: '16px !important',
                    border: '0px solid transparent !important',
                    backgroundClip: 'content-box !important',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#8c8c8c !important',
                  },
                  '*::-webkit-scrollbar-corner': {
                    background: 'transparent !important',
                  },
                }}
                style={{
                  scrollbarWidth: 'auto',
                }}
              >
                <Image
                  // src={TweetImage}
                  // src={'https://ipfs.io/ipfs/bafkreidxofoqnlhzdgsbvorkpadstxk4tabxnqvcsbmnlua4m7ok74hqqe?file.png'}
                  src={nftDetail?.display_image_url}
                  objectFit="cover"
                  width="inherit"
                  borderRadius="26px"
                  marginRight="20px"
                />
              </Box>
              <Box
                borderRadius="26px"
                background="rgba(255, 255, 255, 0.10)"
                padding="16px"
                width="-webkit-fill-available"
                height="fit-content"
              >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Text color=" #FFF" fontSize="24px" fontWeight="700">
                    {nftDetail?.name}
                  </Text>
                  <Text
                    color="rgba(255, 255, 255, 0.50)"
                    fontSize="14px"
                    fontWeight="600"
                    textDecoration="underline"
                    cursor="pointer"
                    onClick={handleShare}
                  >
                    Share
                  </Text>
                </Box>
                <Box marginTop="24px" marginBottom="10px">
                  <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="10px" />
                </Box>
                <Box display="flex" flexDirection="column" gap="5px">
                  <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="10px">
                    <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                      Network
                    </Text>
                    <Box
                      borderRadius="19px"
                      background="rgba(255, 255, 255, 0.10)"
                      padding="4px 4px 4px 10px"
                      display="flex"
                      gap="6px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                        Ethereum
                      </Text>
                      <EthereumLogo boxSize={6} />
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="10px">
                    <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                      Creator
                    </Text>
                    {nftDetail?.owner?.twitter_username ? (
                      <Box display="flex" justifyContent="center" gap="6px">
                        <Text
                          color="#FFFFFF"
                          fontSize="14px"
                          fontWeight="600"
                          cursor="pointer"
                          textDecoration="none" // Ensure the bg on hover works
                          _hover={{
                            textDecoration: 'underline',
                            color: '#04D3FF',
                          }}
                          onClick={() => onOwnerClick(nftDetail?.owner)}
                        >
                          {nftDetail?.owner?.twitter_username}
                        </Text>
                        <VerifiedLogo />
                      </Box>
                    ) : (
                      <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                        {nftDetail?.owner?.wallet_address ? nftDetail?.owner?.wallet_address : '-'}
                      </Text>
                    )}
                  </Box>
                </Box>
                <Box marginTop="24px" marginBottom="10px">
                  <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="18px" />
                </Box>
                <Box display="flex" gap="12px">
                  <Button
                    width="100%"
                    borderRadius="15px"
                    backgroundColor="#222"
                    padding="16px 32px"
                    color=" #FFF"
                    fontSize="16px"
                    fontWeight=" 600"
                    border=" 0.5px solid rgba(255, 255, 255, 0.20)"
                    h="44px"
                    _hover={{
                      color: 'white',
                      bg: 'rgba(255, 255, 255, 0.20)',
                    }}
                    leftIcon={
                      nftDetail?.is_favorite ? (
                        <HeartFilledIcon color="#D9D9D9" width="20px" height="20px" />
                      ) : (
                        <HeartIcon color="red" />
                      )
                    }
                    onClick={nftDetail?.is_favorite ? removeFromFavorites : onAddToFavClick}
                  >
                    {nftDetail?.is_favorite ? 'Remove from favorites' : 'Add to favorites'}
                  </Button>
                </Box>
              </Box>
            </Box>
            <CommentSection nftId={parseInt(nftDetail?.identifier || '')} id={nftDetail?.id} />
          </Box>
        </Box>
      )}
    </>
  );
}
